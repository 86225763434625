import { Enum } from '../data/enum';
import { Role } from './IUserInfo';
import { TId } from './TId';

export interface IEvent {
  id: TId;
  objectType: ObjectType;
  objectName: string;
  objectState: string;
  objectCode: string;
  error: boolean;
  action: ObjectAction;
  message?: string;
  data?: string;
  dateAddTs: string;
  userId: TId;
  userShowName: string;
  userRole: Role;
}

export enum ObjectType {
  Complex = 'complex',
  Folder = 'folder',
  Printer = 'printer',
  CrInstance = 'cr_instance',
  Service = 'service',
  PrintJob = 'print_job',
  SystemSetting = 'system_setting',
  User = 'user',
  System = 'system',
}

export const ObjectTypeEnum = new Enum<ObjectType>([
  [ObjectType.Complex, 'Задание'],
  [ObjectType.Folder, 'Сетевая папка'],
  [ObjectType.Printer, 'Принтер'],
  [ObjectType.CrInstance, 'OCR'],
  [ObjectType.Service, 'Процесс'],
  [ObjectType.PrintJob, 'Задание на печать'],
  [ObjectType.SystemSetting, 'Настройка'],
  [ObjectType.User, 'Пользователь'],
  [ObjectType.System, 'Система'],
]);

export enum ObjectAction {
  Create = 'create',
  Delete = 'delete',
  InWorkEnd = 'end',
  GetToWork = 'get_to_work',
  InWorkToRecognize = 'in_work__to_recognize',
  InWorkSave = 'in_work__save',
  InWorkSaveError = 'in_work__save_error',
  InWorkEndError = 'in_work__end_error',
  InWorkAddTempImage = 'in_work__add_temp_image',
  InWorkViewPetition = 'in_work__view_petition',
  InWorkViewAward = 'in_work__view_award',
  UpdateBarcode = 'update_barcode',
  UpdateAwardsFactCount = 'update_awards_fact_count',
  Revert = 'revert',
  Retry = 'retry',
  ValidationError = 'validation__error',
  ValidationErrorParity = 'validation__error_parity',
  ValidationErrorDpi = 'validation__error_dpi',
  ValidationErrorFormat = 'validation__error_page_format',
  ValidationEnd = 'validation__end',
  BuildStructureStart = 'build_structure__start',
  BuildStructureEnd = 'build_structure__end',
  BuildStructureNoCode = 'build_structure__no_code',
  BuildStructureDuplicateCode = 'build_structure__duplicate_code',
  BuildStructureError = 'build_structure__error',
  RecognitionStart = 'recognition__start',
  RecognitionError = 'recognition__error',
  RecognitionEnd = 'recognition__end',
  PrepareError = 'prepare__error',
  PrepareEnd = 'prepare__end',
  PrepareStart = 'prepare__start',
  ValidationStart = 'validation__start',
  LoadingError = 'loading__error',
  LoadingEnd = 'loading__end',
  SentError = 'sent__error',
  SentEnd = 'sent__end',
  SendError = 'send__error',
  SendEnd = 'send__end',
  SendStart = 'send__start',
  ToAssemble = 'to_assemble',
  PartComplete = 'part_complete',
  ToAssembleError = 'to_assemble__error',
  AssembleError = 'assemble__error',
  AssembleEnd = 'assemble__end',
  AssembleStart = 'assemble__start',
  PartsReady = 'parts_ready',
  PartsNotReady = 'parts_not_ready',
  ParentReverted = 'parent_reverted',
  RecognitionRaisePriority = 'recognition__raise_priority',
  InWorkPrintEnd = 'in_work__end',
  DoPrint = 'do_print',
  Printing = 'printing',
  PrintingEnd = 'printing__end',
  Update = 'update',
  Start = 'start',
  Stop = 'stop',
  Restart = 'restart',
  ErrorDead = 'error__dead',
  Dead = 'dead',
  Alive = 'alive',
  UpdatePassword = 'update_password',
  Activate = 'activate',
  Deactivate = 'deactivate',
  LoadingDuplicateAccept = 'loading__duplicate_accept',
  LoadingDuplicateCancel = 'loading__duplicate_cancel',
  CreateDuplicate = 'create_duplicate',
}

export const ObjectActionEnum = new Enum<ObjectAction>([
  [ObjectAction.Create, 'Создание'],
  [ObjectAction.Delete, 'Удаление'],
  [ObjectAction.InWorkEnd, 'Завершение обработки'],
  [ObjectAction.GetToWork, 'Взятие в обработку'],
  [ObjectAction.InWorkToRecognize, 'Отправка на распознавание'],
  [ObjectAction.InWorkSave, 'Сохранение'],
  [ObjectAction.InWorkSaveError, 'Ошибка сохранения'],
  [ObjectAction.InWorkEndError, 'Ошибка завершения'],
  [ObjectAction.InWorkAddTempImage, 'Загрузка изображений'],
  [ObjectAction.InWorkViewPetition, 'Просмотр ходатайства'],
  [ObjectAction.InWorkViewAward, 'Просмотр НЛ'],
  [ObjectAction.UpdateBarcode, 'Изменение штрихкода'],
  [ObjectAction.UpdateAwardsFactCount, 'Указание фактического количества НЛ'],
  [ObjectAction.Revert, 'Откат действия'],
  [ObjectAction.Retry, 'Повтор действия'],
  [ObjectAction.ValidationError, 'Ошибка проверки'],
  [ObjectAction.ValidationErrorParity, 'Ошибка проверки четности'],
  [ObjectAction.ValidationErrorDpi, 'Ошибка проверки DPI'],
  [ObjectAction.ValidationErrorFormat, 'Ошибка проверки формата'],
  [ObjectAction.ValidationEnd, 'Окончание проверки'],
  [ObjectAction.BuildStructureStart, 'Начало построения структуры'],
  [ObjectAction.BuildStructureEnd, 'Окончание построения структуры'],
  [ObjectAction.BuildStructureNoCode, 'При построении структуры не найден штрихкод'],
  [ObjectAction.BuildStructureDuplicateCode, 'Найдено несколько штрихкодов'],
  [ObjectAction.BuildStructureError, 'Ошибка построения структуры'],
  [ObjectAction.RecognitionStart, 'Начало распознавания'],
  [ObjectAction.RecognitionError, 'Ошибка распознавания'],
  [ObjectAction.RecognitionEnd, 'Окончание распознавания'],
  [ObjectAction.PrepareError, 'Ошибка при подготовке'],
  [ObjectAction.PrepareEnd, 'Окончание подготовки'],
  [ObjectAction.PrepareStart, 'Начало подготовки'],
  [ObjectAction.ValidationStart, 'Начало проверки'],
  [ObjectAction.LoadingError, 'Ошибка загрузки'],
  [ObjectAction.LoadingEnd, 'Окончание загрузки'],
  [ObjectAction.SentError, 'Отклонено наградами'],
  [ObjectAction.SentEnd, 'Принято наградами'],
  [ObjectAction.SendError, 'Ошибка отправки'],
  [ObjectAction.SendEnd, 'Окончание отправки'],
  [ObjectAction.SendStart, 'Начало отправки'],
  [ObjectAction.ToAssemble, 'Запуск сборки'],
  [ObjectAction.PartComplete, 'Завершение части'],
  [ObjectAction.ToAssembleError, 'Ошибка запуска сборки'],
  [ObjectAction.AssembleError, 'Ошибка сборки'],
  [ObjectAction.AssembleEnd, 'Окончание сборки'],
  [ObjectAction.AssembleStart, 'Начало сборки'],
  [ObjectAction.PartsReady, 'Завершение всех частей'],
  [ObjectAction.PartsNotReady, 'Откат'],
  [ObjectAction.ParentReverted, 'Откат'],
  [ObjectAction.RecognitionRaisePriority, 'Повышение приоритета'],
  [ObjectAction.InWorkPrintEnd, 'Подтверждение выполнения печати'],
  [ObjectAction.DoPrint, 'Отправка на печать'],
  [ObjectAction.Printing, 'Печать'],
  [ObjectAction.PrintingEnd, 'Завершение печати'],
  [ObjectAction.Update, 'Изменение'],
  [ObjectAction.Start, 'Запуск'],
  [ObjectAction.Stop, 'Отсановка'],
  [ObjectAction.Restart, 'Перезапуск'],
  [ObjectAction.ErrorDead, 'Стало недоступно'],
  [ObjectAction.Dead, 'Стало недоступно'],
  [ObjectAction.Alive, 'Стало доступно'],
  [ObjectAction.UpdatePassword, 'Изменение пароля'],
  [ObjectAction.Activate, 'Активация'],
  [ObjectAction.Deactivate, 'Деактивация'],
  [ObjectAction.LoadingDuplicateAccept, 'Подтверждение загрузки дубликата'],
  [ObjectAction.LoadingDuplicateCancel, 'Отмена загрузки дубликата'],
  [ObjectAction.CreateDuplicate, 'Создание дубликата'],
]);
